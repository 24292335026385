import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { addDepartment, getDepartments, mapDepartment } from '../../api/departments';
import { add, addFailed, map, request, requestSuccess } from '../../views/Departments/slice'
const pending = state => state.departments.pending;
const pendingMapping = state => state.departments.map
function* loadDepartments() {
    const departments = yield call(getDepartments);
    yield put({ type: requestSuccess.toString(), payload: departments });
}
export function* DepartmentsSaga() {
    yield take(request.toString());
    yield call(loadDepartments);
}
function* _addDepartment() {
    let pendingDepartment = yield select(pending);
    try {
        const result = yield call(addDepartment, pendingDepartment);
        yield call(loadDepartments);
    } catch (error) {
        yield call(addFailed);
    }
}
function* mapUser() {
    const toMap = yield select(pendingMapping);
    try {
        yield call(mapDepartment, toMap.employee_id, toMap.department_id);
        yield call(loadDepartments);
    } catch (error) {

    }
}
export function* mapUserSaga() {
    yield takeEvery(map.toString(), mapUser);
}
export function* addDepartmentSaga() {
    yield takeEvery(add.toString(), _addDepartment);

}