import { createSlice } from "@reduxjs/toolkit";
// import { contentData, scanData, compititorLsiListData, contentAnalysis } from "../Components/data";

const initialState = {
  processData: [],
  completedData: [],
  batchProcessLoading:false
};

const ToolSlice = createSlice({
  name: "batchProcessTool",
  initialState,
  reducers: {
   
    setProcessData(state, action) {
      state.processData = action.payload;
    },
    setBatchProcessLoading(state, action) {
      state.batchProcessLoading = action.payload;
    },
    setCompletedData(state, action) {
      state.completedData = action.payload;
    },
    
  },
});

export const {
  setProcessData,
  setBatchProcessLoading,
  setCompletedData
} = ToolSlice.actions;

export default ToolSlice.reducer;
