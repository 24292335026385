import { call, put, take } from 'redux-saga/effects'
import { fetchTeams } from '../../api/teams';

import { request, failed, success } from '../../views/Panel/slices/TeamSlice'
// worker Saga: will be fired on USER_FETCH_REQUESTED actions

function* teamSaga() {
    yield take(request);
    const teams = yield call(fetchTeams);
    if (teams && teams.length > 0) {
        yield put({ type: success.toString(), teams });
    } else {
        yield put(failed);
    }
  
}
export default teamSaga;