import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    departments : []
};

const slice = createSlice({
    name: 'departments',
    initialState,
    reducers: {
        add(state, action) {
            state.pending = action.payload;
        },
        remove(state, action) {
            state.remove = action.payload;
        },
        request(state) {
            state.isLoading = true;
        },
        map(state, action) {
            state.map = {
                department_id: action.payload.department_id,
                employee_id: action.payload.employee_id
            }
        },
        mapSuccess(state) {
            state.map = {};
        },
        addSuccess(state) {
            state.pending = {};
            
        },
        addFailed(state){
            state.add_failed = true;
        },
        requestSuccess(state, action) {
            return { departments: [...action.payload] };
        }
    },
});

export const { add, remove, request, requestSuccess, map, mapSuccess, addSuccess,addFailed } = slice.actions;

export default slice.reducer;
