import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    content: [],
    loading: true
}

const ContentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        getContent(state) {
            state.loading = true;
        },
        gotContent(state, action) {
            state.loading = false;
            state.content = [...action.payload];
        },
        addContent(state, action) {

        },
        removeContent(state, action) {

        },
        updateContent(state, action) {

        },
        getReport(state, action) {

        }
    }
});

export const { getContent, gotContent, removeContent, updateContent, getReport, addContent } = ContentSlice.actions

export default ContentSlice.reducer