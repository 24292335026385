import { addStructureAsync, fetchContent, fetchStructures, getReportAsync } from "../../api/content";
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { add, request, success } from "../../views/Calendar/CalendarSlice";
import { getContent as contentRequest, gotContent } from "../../views/Content/ContentSlice";
import { FetchReport, ReportSuccess } from "../../views/Content/ContentReport/ReportSlice";

const calendar = state => state.calendar;
const contentStore = state => state.content.content;
function* getStructures() {
    const structures = yield call(fetchStructures);
    yield put({ type: success.toString(), payload: structures });

}
function* addStructure(action) {
    const structure = action.payload;
    try {
        yield call(addStructureAsync, structure);
        yield call(getStructures);
    } catch (error) {

    }

}
function* doNothing() {
    const store = yield select(calendar);
    yield put({ type: success.toString(), payload: [...store.data] });
}
export function* contentSaga() {
    yield take(request.toString());
    yield call(getStructures);
    yield takeEvery(add.toString(), addStructure);
    yield takeEvery(request.toString(), doNothing);
}
function* postpone() {
    const existingContent = yield select(contentStore);
    yield put({ type: gotContent.toString(), payload: existingContent });
}
function* getContent() {
    const existingContent = yield select(contentStore);
    if (!existingContent || !existingContent.length) {
        try {
            const contents = yield call(fetchContent);
            yield put({ type: gotContent.toString(), payload: contents });
        } catch (error) {
            yield put({ type: gotContent.toString(), payload: [] });
        }
    } else {
        yield call(postpone);
    }

}
function* getContentReport(action) {
    try {
        const report = yield call(getReportAsync, action.payload);
        yield put({ type: ReportSuccess.toString(), payload: report });
    } catch (error) {

    }
}
export function* contentReportSaga() {
    yield takeEvery(FetchReport.toString(), getContentReport)
}
export function* contentOverviewSaga() {
    yield take(contentRequest.toString());
    yield call(getContent);
    yield takeEvery(contentRequest.toString(), postpone);
}