import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { fetchLinks, getLandingPages, getSubs, addLink, updateLinkAsync, removeLinkAsync } from '../../api/affiliate';
import { request, success, change, add, update, updateFailed, remove } from '../../views/Affiliate/AffiliateSlice';
const pending = state => state.affiliate.pending;
function* loadLinks() {
    const links = yield call(fetchLinks);
    const landingpages = yield call(getLandingPages)
    const subs = yield call(getSubs);
    yield put({ type: success.toString(), payload: { links, landingpages, subs } });
}
function* insert() {

    const link = yield select(pending);
    yield call(addLink, link);
    yield call(loadLinks);
}
function* updateLink() {
    const link = yield select(pending);
    try {
        const result = yield call(updateLinkAsync, link);
        if (result) {
            yield call(loadLinks);

        } else {
            yield put({ type: updateFailed.toString() });
        }
    } catch (error) {
        yield put({ type: updateFailed.toString() });
    }


}
export function* updateLinkSaga() {
    yield takeLatest(update.toString(), updateLink);
}
export function* affiliateSaga() {
    yield take(request.toString());
    yield call(loadLinks);
    yield takeLatest(change.toString(), loadLinks);

}
function* removeLink(action) {
    const id = action.payload;
    try {
        yield call(removeLinkAsync, id);
        yield call(loadLinks);
    } catch (error) {
        console.log(error);
    }

}
export function* removeLinkSaga() {
    const action = yield takeEvery(remove.toString(), removeLink)

}
export function* affiliateAddSaga() {
    yield takeLatest(add.toString(), insert);
}