import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  loadingTopRelatedRequest: false,
  loadingTopQueriesRequest: false,
  loadingRelatedQueriesRequest: false,
  loadingAllRelatedQueriesRequest: false,

  topRelatedTopic: [],
  topRelatedQueries: [],
  relatedQueries: [],
  allRelatedQueries: [],
  allRelatedQueriesInterestOvertime:[],

  search:{},
  loadingSearch: false,

  failed: false,
  pending: {},
  trendings: [],
  region: [],
  dataForSeoRegions:[],
};

const TrendingSlice = createSlice({
  name: "trending",
  initialState,
  reducers: {
    request(state) {
      state.loading = true;
    },
    success(state, action) {
      state.trendings = action.payload;
      state.loading = false;
    },
    setRegion(state, action) {
      state.region = action.payload;
    },

    requestTopRelatedTopics(state) {
      state.loadingTopRelatedRequest = true;
    },
    requestTopRelatedQueries(state) {
      state.loadingTopQueriesRequest = true;
    },
    requestRelatedQueries(state) {
      state.loadingRelatedQueriesRequest = true;
    },
    requestAllRelatedQueries(state) {
      state.loadingAllRelatedQueriesRequest = true;
    },

    successTopRelatedTopic(state, action) {
      state.topRelatedTopic = action.payload;
      state.loadingTopRelatedRequest = false;
    },
    successTopRelatedQueries(state, action) {
      state.topRelatedQueries = action.payload;
      state.loadingTopQueriesRequest = false;
    },
    successRelatedQueries(state, action) {
      state.relatedQueries = action.payload;
      state.loadingRelatedQueriesRequest = false;
    },
    successAllRelatedQueries(state, action) {
      state.allRelatedQueries = action.payload;
      state.loadingAllRelatedQueriesRequest = false;
    },
    updateStatusAllRelatedQueries(state, payload) {
      console.log({payload_1: payload.payload})
      let rowData = {...payload.payload.rowData}
      let value = payload.payload.value
      console.log({rowData, value})
      rowData.status = value
      let index = state.allRelatedQueries.findIndex(item => item.query === rowData.query && item.region === rowData.region && item.persona === rowData.persona);
      state.allRelatedQueries[index] = rowData

      state.allRelatedQueries = [...state.allRelatedQueries];
      state.loadingAllRelatedQueriesRequest = false;
    },
    successAllRelatedQueriesInterestOvertime(state, action) {
      state.allRelatedQueriesInterestOvertime = action.payload;
    },

    setSearch(state, action) {
        state.search = action.payload;
        state.loadingSearch= true;
    },
    setDataForSeoRegions(state, action) {
      state.dataForSeoRegions = action.payload;
    },
    requestCompleteRelatedTopics(state) {
      state.loadingRelatedRequest = false;
    },
  },
});

export const {
  request,
  success,
  setRegion,
  requestRelatedTopics,
  successRelatedTopic,
  requestCompleteRelatedTopics,

  requestTopRelatedTopics,
  requestTopRelatedQueries,
  requestRelatedQueries,
  requestAllRelatedQueries,
  
  successTopRelatedTopic,
  successTopRelatedQueries,
  successRelatedQueries,
  successAllRelatedQueries,
  updateStatusAllRelatedQueries,
  successAllRelatedQueriesInterestOvertime,
  
  setSearch,
  setDataForSeoRegions,
  
} = TrendingSlice.actions;

export default TrendingSlice.reducer;
