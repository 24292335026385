import axios from 'axios'
export const fetchCrwalUrls = async (params = {}) => {
    console.log({ params1: params })
    const queryString = new URLSearchParams(params).toString();
    console.log({ queryString })
    return new Promise((resolve) => {
        return fetch(`${process.env.REACT_APP_API_URL}/crawlUrl/list?${queryString}`, {
            params
        }).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            })
        }).catch(function () {
            resolve([]);
        })
    })
}

export const addCrawlLink = async (link) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/crawlUrl/insert`, link).then(function (response) {
            resolve(response.data);

        }).catch(function (error) {
            reject(error);
        })
    })
}