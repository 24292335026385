export const  fetchTeams =async () => {
    return new Promise((resolve) => {
        return fetch(`${process.env.REACT_APP_API_URL}/teams/list`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            })
        }).catch(function () {
            resolve([]);
        })
    })
}