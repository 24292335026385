import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    loading: false,
    teams: []
};

const TeamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        request(state) {
            state.loading = true
        },
        success(state, action) {
            return {
                loading: false,
                teams: [...action.teams]
            }
        },
        failed(state) {
            state.loading = false;
            state.failed = true;
            state.teams = [];
        }
    },
});

export const { request, success, failed } = TeamSlice.actions;

export default TeamSlice.reducer;
