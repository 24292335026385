import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  failed: false,


  compititorLsiList: [],
  compititorLsiListLoading: false,
  updateContentLoading: false,
  newAndUpdateContentFormData: {},
  
  personality: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  template: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
    instructionToggle: false
  },
};

const ToolSlice = createSlice({
  name: "aiTool",
  initialState,
  reducers: {
    request(state) {
      state.loading = true;
    },
    success(state, action) {
      state.trendings = action.payload;
      state.loading = false;
    },

    setCompititorLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setContentSelectedLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setCompititorLsiListLoading(state, action) {
      state.compititorLsiListLoading = action.payload;
    },
    setUpdateContentLoading(state, action) {
      state.updateContentLoading = action.payload;
    },
    setNewAndUpdateContentFormData(state, action) {
      state.newAndUpdateContentFormData = action.payload;
    },
    setPersonality(state, action) {
      state.personality = action.payload;
    },
    setTemplate(state, action) {
      state.template = action.payload;
    },
    

    
  },
});

export const {
  request,
  success,
  setCompititorLsiList,
  setContentSelectedLsiList,
  setCompititorLsiListLoading,
  setUpdateContentLoading,
  setNewAndUpdateContentFormData,
  setPersonality,
  setTemplate
} = ToolSlice.actions;

export default ToolSlice.reducer;
