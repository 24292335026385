import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    crawl_url_list: {
        parentData: [],
        childData: []
    }
};

const CrawlUrlSlice = createSlice({
    name: 'crawlUrl',
    initialState,
    reducers: {
        request: (state) => {
            state.loading = true;
        },
        success: (state, action) => {
            return {
                loading: false,
                crawl_url_list: action.payload
            }
        }
    },
});

export const { request, success } = CrawlUrlSlice.actions;

export default CrawlUrlSlice.reducer;