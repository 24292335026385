import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: [],
    failed: false,
    pending: {}
};

const CalendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        request(state) {
            state.loading = true;
        },
        success(state, action) {
            return {
                loading: false,
                data: [...action.payload],
                pending: { ...state.pending },
                failed:false
            }
        },
        failed(state, action) {
            state.failed = true;
        },
        add(state) {

        },
        addSuccess(state) {
            state.pending = false;
        }
    },
});

export const { request, success, failed, add } = CalendarSlice.actions;

export default CalendarSlice.reducer;
