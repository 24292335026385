import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    links: [],
    failed: false,
    pending: {},

};

const AffiliateSlice = createSlice({
    name: 'affiliateLinks',
    initialState,
    reducers: {
        request(state) {
            state.loading = true;
        },
        success(state, action) {
            let landingPages = [...action.payload.landingpages];
            landingPages.forEach((landingPage, index) => {
                if (landingPage.provider == 'NordVPN') {
                    landingPage.link = landingPage.link.replace('&aff_sub=', `&appId=${index}&aff_sub=`)
                }
            })
            return {
                links: [...action.payload.links],
                landingPages: [...landingPages],
                subs: [...action.payload.subs],
                loading: false,
                failed: false,
                pending: {}
            }
        },

        change(state) {
            state.loading = true;
        },
        add(state, action) {
            state.pending = action.payload;
            state.loading = true;
        },
        update(state, action) {
            state.update_failed = false;
            state.pending = action.payload;
        },
        updateFailed(state, action) {
            state.update_failed = true;
        },
        remove(state, action) {

        }
    },
});

export const { request, success, change, add, remove, update, updateFailed } = AffiliateSlice.actions;

export default AffiliateSlice.reducer;
