import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eeatFormData: {},
};

const ToolSlice = createSlice({
    name: 'aiTool',
    initialState,
    reducers: {
        setEeatFormData(state, action) {
            state.eeatFormData = action.payload;
        },
    },
});

export const { setEeatFormData } = ToolSlice.actions;

export default ToolSlice.reducer;
