import axios from "axios";
import moment from "moment";

export const getTopRelatedTopics = async () => {

  return new Promise((resolve) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/trending/getTopRelatedTopics`,
    };

    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getTopRelatedQueries = async () => {

  return new Promise((resolve) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/trending/getTopRelatedQueries`,
    };

    axios
      .request(options)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getRelatedQueries = async (searchData) => {
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/getRelatedQueries`, {
        params: searchData
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getPersonaRelatedQueriesById = async (id, regions = '', breakoutData = '') => {
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/getRelatedQueriesByPersonaId`, {
        params: {
          persona_id: id,
          regions,
          breakout_data: breakoutData
        }
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getRelatedQueriesByPersonaIdAllRegion = async (params = {}) => {
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/getRelatedQueriesByPersonaIdAllRegion`, {
        params: {
          persona_id: params?.persona_id,
          regions: params?.regions,
          time: params?.time
        }
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getAllRelatedQueries = async (searchData) => {
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/getAllRelatedQueries`, {
        params: searchData
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getAllRelatedtopics = async (searchData) => {
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/getAllRelatedTopics`, {
        params: searchData
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (e) {
        resolve(e);
      });
  });
};

export const getRegions = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/regions`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPerosnas = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/personas`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllPerosnas = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/all-personas`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getProjects = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/trending/projects`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPersonaRelatedQueries = async (params) => {

  return new Promise((resolve) => {

    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_TREND_API_KEY}`
      },
      body: JSON.stringify(
        [
          {
            location_name: "United States",
            time_range: "past_day",
            type: "web",
            keywords: ["Netflix"],
            category_code: 0,
            item_types: ["google_trends_queries_list"],
            ...params
          }
        ]
      )
    };

    fetch(`${process.env.REACT_APP_TREND_API_URL}/v3/keywords_data/google_trends/explore/live`, requestOptions)
      .then(async response => {
        try {

          const data = await response.json();
          let region = data.tasks[0].data.location_name;
          let persona = data.tasks[0].data.keywords[0];
          let allRisiogData = data.tasks[0].result[0].items[0].data.rising;

          allRisiogData.map(item => {
            item.persona = persona;
            item.region = region;
          })
          console.log({ params })

          axios.get(`${process.env.REACT_APP_API_URL}/trending/get-log`, {
            params: {
              employee: params.user_id,
              region,
              persona,
            }
          }).then(resp => {
            console.log({ resp: resp.data })
            var selected_item = {};
            for (const related_query of allRisiogData) {
              selected_item = resp.data.find(x => x.query == related_query.query)
              if (selected_item) {
                related_query.status = selected_item?.status
              } else {
                related_query.status = 0
              }
            }
            axios.post(`${process.env.REACT_APP_API_URL}/trending/insert-log`,
              {
                user_id: params.user_id,
                region: region,
                persona: persona,
                bulk_query: allRisiogData
              })

            console.log({ allRisiogData })
            resolve(allRisiogData);
          })
          // fetch(`${process.env.REACT_APP_API_URL}/trending/insert-log`, 
          // {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": 'application/json'
          //   },
          //   body: JSON.stringify({
          //       user_id:params.user_id ,
          //       region,
          //       persona,
          //       related_queries: allRisiogData
          //   }),
          // }).then(resp=>console.log(resp))
          // .catch(err=>console.log(err))


        } catch (error) {
          resolve([]);
        }

      })
      .catch(e => {
        resolve(e);
      });
  });
};
export const getSportRelatedQueries = async (params) => {

  return new Promise((resolve) => {

    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_TREND_API_KEY}`
      },
      body: JSON.stringify(
        [
          {
            location_name: "United States",
            time_range: "past_day",
            type: "web",
            keywords: ["Netflix"],
            category_code: 0,
            item_types: ["google_trends_queries_list"],
            ...params
          }
        ]
      )
    };

    fetch(`${process.env.REACT_APP_TREND_API_URL}/v3/keywords_data/google_trends/explore/live`, requestOptions)
      .then(async response => {
        try {

          const data = await response.json();
          let region = data.tasks[0].data.location_name;
          let sport = data.tasks[0].data.keywords[0];
          let allRisiogData = data.tasks[0].result[0].items[0].data.rising;

          allRisiogData.map(item => {
            item.category = sport;
            item.persona = 'sport';
            item.region = region;
          })
          console.log({ params })

          axios.get(`${process.env.REACT_APP_API_URL}/trending/get-log`, {
            params: {
              employee: params.user_id,
              region,
              persona: 'sport',
              category: sport
            }
          }).then(resp => {
            console.log({ resp: resp.data })
            var selected_item = {};
            for (const related_query of allRisiogData) {
              selected_item = resp.data.find(x => x.query == related_query.query)
              if (selected_item) {
                related_query.status = selected_item?.status
              } else {
                related_query.status = 0
              }
            }
            axios.post(`${process.env.REACT_APP_API_URL}/trending/insert-log`,
              {
                user_id: params.user_id,
                region: region,
                persona: 'sport',
                category: sport,
                bulk_query: allRisiogData
              })

            console.log({ allRisiogData })
            resolve(allRisiogData);
          })
          // fetch(`${process.env.REACT_APP_API_URL}/trending/insert-log`, 
          // {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": 'application/json'
          //   },
          //   body: JSON.stringify({
          //       user_id:params.user_id ,
          //       region,
          //       persona,
          //       related_queries: allRisiogData
          //   }),
          // }).then(resp=>console.log(resp))
          // .catch(err=>console.log(err))


        } catch (error) {
          resolve([]);
        }

      })
      .catch(e => {
        resolve(e);
      });
  });
};

export const getDataForSeoTrendingRegions = async () => {
  return new Promise((resolve, reject) => {

    const requestOptions = {
      method: 'GET',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_TREND_API_KEY}`
      },
    };

    fetch(`${process.env.REACT_APP_TREND_API_URL}/v3/keywords_data/google_trends/locations`, requestOptions)
      .then(async response => {
        const data = await response.json();
        resolve(data.tasks[0].result);
      })
      .catch(e => {
        resolve(e);
      });
  });
};

export const getPersonaRelatedQueriesBySerpApi = async (param) => {
  console.log("testing...")
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/trending/getPersonaRelatedQueries`, {
      params: param
    });
    if (response.data && response.data.data) {
      const data = response.data;
      const region = param.location_name;
      let persona = param.keywords;
      let sport = ''
      if (param?.categoryName){
        persona = param?.categoryName ? param?.categoryName : 'sport';
        sport = param.keywords.toString();
      }
      // let allRisingData = response.data.related_queries.rising;
      let allRisingData = response.data.data;

      allRisingData = allRisingData.map(item => {
        item.persona = persona.toString();
        item.region = region;
        if (param?.categoryName){
          item.sport = sport;
          item.category = sport;
        }
        return item;
      });

      allRisingData.sort((a, b) => {
        const aIsBreakout = a?.formattedValue.includes('Breakout');
        const bIsBreakout = b?.formattedValue.includes('Breakout');

        if (aIsBreakout && !bIsBreakout) {
          return -1; // a is Breakout, b is not
        } else if (!aIsBreakout && bIsBreakout) {
          return 1; // b is Breakout, a is not
        } else {
          // Both values are not Breakout or both are Breakout
          // Sort by 'value' field in descending order
          return b?.value - a?.value;
        }
      });

      console.log({ allRisingData });
      console.log({ param });

      await axios.get(`${process.env.REACT_APP_API_URL}/trending/get-log`, {
        params: {
          employee: param.user_id,
          region,
          persona,
          category: sport
        }
      }).then(resp => {
        console.log({ resp: resp.data })
        let selected_item = {};
        for (const related_query of allRisingData) {
          selected_item = resp.data.find(x => x.query === related_query.query)
          if (selected_item) {
            related_query.status = selected_item.status || 0;
          } else {
            related_query.status = 0;
          }
        }

        axios.post(`${process.env.REACT_APP_API_URL}/trending/insert-log`, {
          user_id: param.user_id,
          region,
          persona: persona.toString(),
          category: sport,
          bulk_query: allRisingData,
          provider: 'brightdata'
        });
      });
      console.log({ allRisingData });
      return [];

    } else {
      return []; // Or you can return an empty array or handle the case based on your needs
    }
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throw the error to handle it where the function is called
  }
};
export const getPersonaRelatedQueriesBySerpWowApi = async (param) => {
  console.log({newTest:param})
  try {
    const region = param.location_name;
    const keyword = param.keywords;
    let persona = param.keywords;
    let sport = ''
    if (param?.categoryName){
      persona = param?.categoryName ? param?.categoryName : 'sport';
      sport = param.keywords.toString();
    }
    const response = await axios.get(`https://api.serpwow.com/live/search`, {
      params: {
        api_key:'657BB0777C0940FD9E1068E62B1E3377',
        location:region,
        q: keyword.toString(),
        search_type:'trends',
        time_period:'last_day'
      }
    });

    console.log({ getPersonaRelatedQueries: response });
    console.log({ getPersonaRelatedQueries2: response.data?.related_queries });

    // if (response.data.related_queries && response.data.related_queries.rising) {
    if (response.data && response.data?.related_queries) {
      const data = response.data;
      // let allRisingData = response.data.related_queries.rising;
      let related_queries = response.data.related_queries;
      let allRisingData = []
      let top_queries = []
      if (related_queries.length){
        allRisingData = related_queries[0]?.rising_queries || []
        top_queries = related_queries[0]?.top_queries || []
        allRisingData = [...allRisingData, ...top_queries]
      } 
      allRisingData = allRisingData.map(item => {
        item.persona = persona.toString();
        item.region = region;
        item.title = item.query
        item.formattedValue = item.value_formatted
        if (param?.categoryName){
          item.sport = sport;
          item.category = sport;
        }

        return item;
      });

      allRisingData.sort((a, b) => {
        const aIsBreakout = a?.formattedValue.includes('Breakout');
        const bIsBreakout = b?.formattedValue.includes('Breakout');

        if (aIsBreakout && !bIsBreakout) {
          return -1; // a is Breakout, b is not
        } else if (!aIsBreakout && bIsBreakout) {
          return 1; // b is Breakout, a is not
        } else {
          // Both values are not Breakout or both are Breakout
          // Sort by 'value' field in descending order
          return b?.value - a?.value;
        }
      });

      console.log({ allRisingData });
      console.log({ param });

      await axios.get(`${process.env.REACT_APP_API_URL}/trending/get-log`, {
        params: {
          employee: param.user_id,
          region,
          persona,
          category: sport
        }
      }).then(resp => {
        console.log({ resp: resp.data })
        let selected_item = {};
        for (const related_query of allRisingData) {
          selected_item = resp.data.find(x => x.query === related_query.query)
          if (selected_item) {
            related_query.status = selected_item.status || 0;
          } else {
            related_query.status = 0;
          }
        }

        axios.post(`${process.env.REACT_APP_API_URL}/trending/insert-log`, {
          user_id: param.user_id,
          region,
          persona: persona.toString(),
          category: sport,
          bulk_query: allRisingData,
          provider: 'serpwow'
        });
      });
      console.log({ allRisingData });
      return allRisingData;

    } else {
      return []; // Or you can return an empty array or handle the case based on your needs
    }
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throw the error to handle it where the function is called
  }
};

export const getCategoryRelatedQueriesBySerpApi = async (param) => {
  console.log({param})
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/trending/getPersonaRelatedQueries`, {
      params: param
    });
    if (response.data && response.data.data) {
      const data = response.data;
      const region = param.location_name;
      const persona = param?.categoryName ? param?.categoryName : 'sport';
      let sport = param.keywords.toString();
      let allRisingData = response.data.data;

      allRisingData = allRisingData.map(item => {
        item.persona = persona.toString();
        item.region = region;
        item.sport = sport;
        item.category = sport;
        return item;
      });

      allRisingData.sort((a, b) => {
        const aIsBreakout = a?.formattedValue.includes('Breakout');
        const bIsBreakout = b?.formattedValue.includes('Breakout');

        if (aIsBreakout && !bIsBreakout) {
          return -1; // a is Breakout, b is not
        } else if (!aIsBreakout && bIsBreakout) {
          return 1; // b is Breakout, a is not
        } else {
          // Both values are not Breakout or both are Breakout
          // Sort by 'value' field in descending order
          return b?.value - a?.value;
        }
      });

      console.log({ param });

      await axios.get(`${process.env.REACT_APP_API_URL}/trending/get-log`, {
        params: {
          employee: param.user_id,
          region,
          persona: param?.categoryName ? param?.categoryName : 'sport',
          category: sport
        }
      }).then(resp => {
        console.log({ resp: resp.data })
        let selected_item = {};
        for (const related_query of allRisingData) {
          selected_item = resp.data.find(x => x.query === related_query.query)
          if (selected_item) {
            related_query.status = selected_item.status || 0;
          } else {
            related_query.status = 0;
          }
        }

        axios.post(`${process.env.REACT_APP_API_URL}/trending/insert-log`, {
          user_id: param.user_id,
          region: region,
          persona: param?.categoryName ? param?.categoryName : 'sport',
          category: sport,
          bulk_query: allRisingData
        });
      });
      console.log({ allRisingData });
      return allRisingData;

    } else {
      return []; // Or you can return an empty array or handle the case based on your needs
    }
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throw the error to handle it where the function is called
  }
};
