import { createSlice } from "@reduxjs/toolkit";
import { contentData, scanData, compititorLsiListData, contentAnalysis } from "../Components/data";

const initialState = {
  loading: false,
  failed: false,

  content: "",
  contentEdited: "",
  contentEditedRawText: "",
  contentAnalysis: "",
  contentStreamLoading: false,

  addContentInContent: "",

  compititorLsiList: [],
  compititorLsiListLoading: false,
  updateContentLoading: false,
  updateContent:null,
  newAndUpdateContentFormData: {
    youTube_urls: [''],
    compititor_urls: ['']
  },
  newAndUpdateContentFormActiveTab: '1',
  createAndUpdateContentLoading: false,
  createAndUpdateContentReqStreamToken: '',
  
  contentOutline: [],
  contentOutlineFinalized: [],
  contentOutlineLoading: false,
  
  contentVariations: [],

  scanData: {},
  scanDataLoading: false,

  contentRating: 0,

  highlightContent: [],
  replaceContentPhrase: {},
  deleteContentPhrase: {},
  pointContentPhrase: '',

  globalStepper: null,
  currentActiveStep: 0,

  isStructureViewEnable: false,
};

const ToolSlice = createSlice({
  name: "aiTool",
  initialState,
  reducers: {
    request(state) {
      state.loading = true;
    },
    success(state, action) {
      state.trendings = action.payload;
      state.loading = false;
    },
    setScanData(state, action) {
      state.scanData = action.payload;
    },
    setScanDataLoading(state, action) {
      state.scanDataLoading = action.payload;
    },
    setContent(state, action) {
      state.content = action.payload;
    },
    setContentEdited(state, action) {
      state.contentEdited = action.payload;
    },
    setCompititorLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setNewAndUpdateContentFormData(state, action) {
      state.newAndUpdateContentFormData = action.payload;
    },
    setCreateAndUpdateContentLoading(state, action) {
      state.createAndUpdateContentLoading = action.payload;
    },
    setCompititorLsiListLoading(state, action) {
      state.compititorLsiListLoading = action.payload;
    },
    setUpdateContentLoading(state, action) {
      state.updateContentLoading = action.payload;
    },
    setUpdateContent(state, action) {
      state.updateContent = action.payload;
    },
    setGlobalStepper(state, action) {
      state.globalStepper = action.payload;
    },
    setCurrentActiveStep(state, action) {
      state.currentActiveStep = action.payload;
    },
    setContentOutline(state, action) {
      state.contentOutline = action.payload;
    },
    setContentOutlineLoading(state, action) {
      state.contentOutlineLoading = action.payload;
    },
    setContentOutlineFinalized(state, action) {
      state.contentOutlineFinalized = action.payload;
    },
    setContentEditedRawText(state, action) {
      state.contentEditedRawText = action.payload;
    },
    setContentRating(state, action) {
      state.contentRating = action.payload;
    },
    setHighlightContent(state, action) {
      state.highlightContent = action.payload;
    },
    setReplaceContentPhrase(state, action) {
      state.replaceContentPhrase = action.payload;
    },
    setDeleteContentPhrase(state, action) {
      state.deleteContentPhrase = action.payload;
    },
    setPointContentPhrase(state, action) {
      state.pointContentPhrase = action.payload;
    },
    setNewAndUpdateContentFormActiveTab(state, action) {
      state.newAndUpdateContentFormActiveTab = action.payload;
    },
    setContentAnalysis(state, action) {
      state.contentAnalysis = action.payload;
    },
    setContentStreamLoading(state, action) {
      state.contentStreamLoading = action.payload;
    },
    setCreateAndUpdateContentReqStreamToken(state, action) {
      state.createAndUpdateContentReqStreamToken = action.payload;
    },
    setAddContentInContent(state, action) {
      state.addContentInContent = action.payload;
    },
    setIsStructureViewEnable(state, action) {
      state.isStructureViewEnable = action.payload;
    },
    setContentVariations(state, action) {
      state.contentVariations = action.payload;
    },
    
  },
});

export const {
  request,
  success,
  setScanData,
  setContent,
  setCompititorLsiList,
  setNewAndUpdateContentFormData,
  setCreateAndUpdateContentLoading,
  setCompititorLsiListLoading,
  setGlobalStepper,
  setCurrentActiveStep,
  setContentOutline,
  setContentOutlineLoading,
  setContentOutlineFinalized,
  setContentEdited,
  setScanDataLoading,
  setContentEditedRawText,
  setContentRating,
  setHighlightContent,
  setReplaceContentPhrase,
  setDeleteContentPhrase,
  setPointContentPhrase,
  setNewAndUpdateContentFormActiveTab,
  setContentAnalysis,
  setContentStreamLoading,
  setCreateAndUpdateContentReqStreamToken,
  setAddContentInContent,
  setIsStructureViewEnable,
  setContentVariations,
  setUpdateContentLoading,
  setUpdateContent
} = ToolSlice.actions;

export default ToolSlice.reducer;
