import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { fetchLinks, getLandingPages, getSubs, addLink, updateLinkAsync, removeLinkAsync } from '../../api/affiliate';
import { fetchCrwalUrls } from '../../api/crawlUrl';
import { request, success } from '../../views/CrawlUrl/CrawlUrlSlice';
function* loadLinks(action) {
    const params = action.payload;
    console.log({ sagaParams: params });
    const crawlUrl = yield call(fetchCrwalUrls, params);
    yield put(success(crawlUrl));
}
export function* crawlUrlSaga() {
    yield takeEvery('crawlUrl/request', loadLinks);
}