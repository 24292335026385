import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  getTokens,
  getTrendingDetails,
  getRegions,
  getRelatedTopicAndQueries,
  getTopRelatedTopics,
  getTopRelatedQueries,
  getRelatedQueries
} from "../../api/trending";
import {
  request,
  success,
  setRegion,
  requestRelatedTopics,
  requestTopRelatedTopics,
  requestTopRelatedQueries,
  requestRelatedQueries,
  successTopRelatedTopic,
  successTopRelatedQueries,
  successRelatedQueries,
  successAllRelatedQueriesInterestOvertime
} from "../../views/Tools/Trending/TrendingSlice";

// const getCountries = state => state.trending.country;
import { useDispatch, useSelector } from "react-redux";

const search = state => state.trending.search;

export function* getTrendingRegions() {
  const countries = yield call(getRegions);
  yield put({ type: setRegion.toString(), payload: countries.regions });
}

export function* getTrendingTopRelatedTopic() {

  yield put({ type: requestTopRelatedTopics.toString() });

  const response = yield call(getTopRelatedTopics);

  yield put({ type: successTopRelatedTopic.toString(), payload: response });

}

export function* getTrendingTopRelatedQueries() {

  yield put({ type: requestTopRelatedQueries.toString() });

  const response = yield call(getTopRelatedQueries);

  yield put({ type: successTopRelatedQueries.toString(), payload: response });

}

export function* getTrendingRelatedQueries() {

  const searchData = yield select(search);


  const response = yield call(getRelatedQueries,searchData);

  yield put({ type: successRelatedQueries.toString(), payload: response.related_queries });
  yield put({ type: successAllRelatedQueriesInterestOvertime.toString(), payload: response.interest_over_time });
  

}

export function* getTrendings() {
  yield takeEvery(requestRelatedQueries.toString(), getTrendingRelatedQueries);
}


