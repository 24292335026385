import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filters: {},
    report: null,
}

const ReportSlice = createSlice({
    name: 'contentReport',
    initialState,
    reducers: {
        FetchReport(state, action) {
            state.filters = { ...action.payload };
        },
        ReportSuccess(state, action) {
            state.report = action.payload;
        },

    }
});

export const { FetchReport, ReportSuccess } = ReportSlice.actions

export default ReportSlice.reducer