import axios from 'axios'
export const fetchLinks = async () => {
    return new Promise((resolve) => {
        return fetch(`${process.env.REACT_APP_API_URL}/affiliate/links`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            })
        }).catch(function () {
            resolve([]);
        })
    })
}
export const getLandingPages = async () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/affiliate/pages`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            }).catch(function () {
                resolve([]);
            })
        })
    });

}
export const getSubs = async () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/affiliate/subs`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            }).catch(function () {
                resolve([]);
            })
        })
    });

}
export const addLink = async (link) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/affiliate/links`, link).then(function (response) {
            resolve(true);
        }).catch(function (error) {
            reject(error);
        })
    })
}
export const removeLinkAsync = async (id) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/affiliate/links/remove/${id}`).then(function () {
            resolve(true);
        }).catch(function (error) {
            reject(error);
        })
    });

}
export const updateLinkAsync = async link => {
    return new Promise((resolve, reject) => {
        axios.put(`${process.env.REACT_APP_API_URL}/affiliate/links`, link).then(function (response) {
            resolve(true);
        }).catch(function (error) {
            reject();
        })
    });

}