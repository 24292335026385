import { login, failed, success, checkAuth, toolSuccess, toolFailed } from '../../views/AuthSlice'
import { call, put, select, take, takeLatest, takeEvery, delay } from 'redux-saga/effects'
import { autoAuth, loginRequest, toolAuthToken } from '../../api/auth'


const getAuthorization = state => state.authorization;
function* validateToken(token) {
    const auth = yield select(getAuthorization);

}
function* getToken() {
    const auth = yield select(getAuthorization);
    if (!auth.token) {
        try {
            const result = yield call(autoAuth);
            if (result.user) {
                yield put({ type: success.toString(), payload: result });
            } else {
                yield put({ type: failed.toString() });
            }

        } catch (error) {
            yield put({ type: failed.toString() });
        }
    }
}
export function* autoAuthSaga() {
    yield takeEvery(checkAuth.toString(), getToken);
}
function* authorize() {
    const state = yield select(getAuthorization);
    const { username, password } = state;
    try {
        const result = yield call(loginRequest, username, password);
        yield put({ type: success.toString(), payload: result });
    } catch (error) {
        yield put({ type: failed.toString() });
    }

}

function* authorizeCreateContentTool() {
    const state = yield select(getAuthorization);
    const { username, password } = state;
    try {
        const result = yield call(toolAuthToken, username, password);
        yield put({ type: toolSuccess.toString(), payload: result });
    } catch (error) {
        yield put({ type: toolFailed.toString() });
    }
}
function* authSaga() {
    yield takeLatest(login.toString(), authorize);
    yield takeLatest(login.toString(), authorizeCreateContentTool);
}
export default authSaga;