import axios from 'axios'
export const getDepartments = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}/department`).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })
    });
}
export const addDepartment = async (department) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/department/create`, { department: department }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })
    });
}
export const deleteDepartment = async (id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/department/remove`, { id }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })
    });
}
export const mapDepartment = async (employee_id, department_id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/department/map`, { employee_id, department_id }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })
    });

}