import axios from 'axios';
export const loginRequest = async (username, password) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, { user_name: username, password: password, with_user: 1 }).then(function (response) {
            resolve(response.data);
        }).catch(function () {
            reject();
        })
    })
}
export const autoAuth = async () => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/check-token`).then(function (response) {
            resolve(response.data);
        }).catch(function () {
            reject();
        })
    })
}
export const checkAuth = async () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/login/check`).then(function (response) {

        })
    });
}
export const toolAuthToken = async (username, password) => {
    return new Promise((resolve, reject) => {

        const formData = new FormData();

        formData.append('username', username);
        formData.append('password', password);

        return axios.post(`${process.env.REACT_APP_PYTHON_CONTENT_CREATION_API_URL}/api/token/generate`, formData)
        .then(function (response) {
            resolve(response.data);
        }).catch(function () {
            reject();
        })
    })
}