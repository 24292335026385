import { spawn } from "@redux-saga/core/effects"
import employee, { addEvaluationSaga, designationSaga, evaluationSaga } from './employeeSaga'
import teamSaga from "./teamSaga"
import authSaga, { autoAuthSaga } from "./AuthSaga"
import { affiliateAddSaga, affiliateSaga, removeLinkSaga, updateLinkSaga } from "./AffiliateSaga"
import { contentOverviewSaga, contentReportSaga, contentSaga } from "./ContentSaga"
import { addDepartmentSaga, DepartmentsSaga, mapUserSaga } from "./DepartmentsSaga"
import { getTrendingRegions } from "./TrendingSaga"
import { crawlUrlSaga } from "./CrawlUrlSaga"
export default function* rootSaga() {
    yield spawn(employee)
    yield spawn(teamSaga)
    yield spawn(designationSaga)
    yield spawn(authSaga)
    yield spawn(evaluationSaga)
    yield spawn(affiliateSaga)
    yield spawn(affiliateAddSaga)
    yield spawn(addEvaluationSaga)
    yield spawn(contentSaga)
    yield spawn(autoAuthSaga)
    yield spawn(updateLinkSaga)
    yield spawn(DepartmentsSaga)
    yield spawn(addDepartmentSaga)
    yield spawn(mapUserSaga)
    yield spawn(removeLinkSaga)
    yield spawn(contentOverviewSaga)
    yield spawn(contentReportSaga)
    yield spawn(getTrendingRegions)
    yield spawn(crawlUrlSaga)
}